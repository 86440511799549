// cf-qr-pay-list.js

export const PayAppList = [
    { 
        name: '페이북1', 
        scheme: 'ispmobile://',
        image: 'assets/paybook.png'  // 예제 이미지 URL
    },
    { 
        name: '우리카드', 
        scheme: 'newsmartpib://',
        image: 'assets/우리카드.png'  // 예제 이미지 URL
    },    
    { 
        name: '토스페이', 
        scheme: 'supertoss://',
        image: 'assets/toss.png'  // 예제 이미지 URL
    }
];