import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { headerFilteringStateInitializer } from '@mui/x-data-grid/internals';

import { checkAppsInstalled } from '../utils/appDetection'; 

import { PayAppList } from '../utils/cf-qr-pay-list';
import PayList from '../components/cf-pay-list';

import '../styles/cf-qr-reader.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function CFQrReader() {
    const isMobile = useMediaQuery('(max-width:600px)');
    // 화면 크기에 따른 높이 설정
    const containerHeight = isMobile ? 'calc(100vh - 100px)' : 'calc(100vh - 50px)';

    // const { order_uid } = useParams();
    const params = useParams();
    
    const query = useQuery();
    const strCd = query.get('str_cd');
    const orderUid = query.get('order_uid');

    console.log('query: ', query);
    //   const [data, setData] = useState(null);
    const [data, setData] = useState({
        str_cd: 1,
        amt: 0,
        cnt: 0,
        detail: [
            // { seq: 1, prodNm: '', qty: 1, prc: 1500, amt: 1500 },
            // { seq: 2, prodNm: '(ICE)카페라떼', qty: 1, prc: 3500, amt: 3500 }
        ]
    });

    const [appsInstalled, setAppsInstalled] = useState({});

    useEffect(() => {
        // ordNo를 사용하여 API를 호출하고 데이터를 가져옵니다. 
        const fetchData = async () => {
            try {
                // const response = await fetch(`http://192.168.101.25:8080/api/qr/userPay?str_cd=${strCd}&order_uid=${orderUid}`);
                const response = await fetch(`http://3.36.24.58:5001/api/qr/userPay?str_cd=${strCd}&order_uid=${orderUid}`);
                const result = await response.json();
                
                if (result.status_code !== 500) {
                    setData(result);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [orderUid]);

    useEffect(() => {
        // 앱 스킴 배열
        const appSchemes = [
            { name: '페이북1', url: 'ispmobile://' },
            { name: '우리카드', url: 'newsmartpib://' },
            { name: '토스페이', url: 'supertoss://' }
        ];

        // 앱 설치 여부 확인
        // checkAppsInstalled(appSchemes, setAppsInstalled);
    }, []);

    const rows = data.detail.map(item => ({
        id: item.seq,
        prodNm: item.prodNm,
        qty: item.qty,
        prc: item.prc,
        amt: item.amt
    }));

    const totalQty = data.detail.reduce((sum, item) => sum + item.qty, 0);
    const totalAmt = data.detail.reduce((sum, item) => sum + item.amt, 0);

    const columns = [
        { field: 'id', headerName: '순번', width: 20, headerAlign: 'center' },
        { field: 'prodNm', headerName: '제품명', flex: 1, headerAlign: 'center' },
        { field: 'qty', headerName: '수량', width: 20, headerAlign: 'center' },
        {
            field: 'prc',
            headerName: '단가',
            width: 80,
            headerAlign: 'center',
            type: 'number',
            valueFormatter: (params) => {
                if (typeof params.value === 'number') {
                    return params.value.toLocaleString();
                }
                return params.value;
            }
        },
        {
            field: 'amt',
            headerName: '금액',
            width: 100,
            headerAlign: 'center',
            type: 'number',
            valueFormatter: (params) => {
                if (typeof params.value === 'number') {
                    return params.value.toLocaleString();
                }
                return params.value;
            }
        }
    ];

    return (
        <Box sx={{ height: containerHeight, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '10px', padding: '16px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                <Typography variant="body2">컴팩 매장</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '18px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                <Typography variant="body2">주소: 서울시 영등포구 의사당대로 83,</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100px'  }}>
                <Typography variant="body2">서울 핀테크랩 4층(오투타워)</Typography>
            </Box>

            <Box className="data-grid-container">
                <DataGrid
                    className="data-grid" // CSS 파일에서 설정한 클래스명
                    rows={rows}
                    columns={columns}
                    hideFooter
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '18px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                <Typography variant="body2">총 수량: {totalQty}</Typography>
                <Typography variant="body2">총 합계: {totalAmt.toLocaleString()} 원</Typography>
            </Box>
            <Box sx={{ padding: '16px', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                <Typography variant="h6">설치된 앱 목록:</Typography>
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <ul>
                    {Object.entries(appsInstalled).map(([appName, isInstalled]) => (
                        <li key={appName}>
                            {appName}: {isInstalled ? '설치됨' : '설치되지 않음'}
                        </li>
                    ))}
                </ul>
                <PayList />
                </Box>
            </Box>
        </Box>
    );
}

export default CFQrReader;
