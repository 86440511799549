import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { PayAppList } from '../utils/cf-qr-pay-list'; 

function PayList() {
    const handleCardClick = (scheme) => {
        window.location.href = scheme;
    };

    return (
        <Box sx={{
            overflowX: 'auto',
            display: 'flex',
            gap: '8px',
            padding: '16px',
            alignItems: 'center',
            '&::-webkit-scrollbar': {
                display: 'none' // Hide scrollbar in WebKit-based browsers
            }
        }}>
            {PayAppList.map((app, index) => (
                <Card
                    key={app.name}
                    sx={{
                        width: '100px',
                        minWidth: '100px',
                        textAlign: 'center',
                        boxShadow: 3,
                        opacity: index === 0 || index === PayAppList.length - 1 ? 0.7 : 1, // Make edge cards slightly transparent
                        transform: index === 0 || index === PayAppList.length - 1 ? 'scale(0.9)' : 'none', // Slightly scale down edge cards
                        transition: 'transform 0.3s ease, opacity 0.3s ease',
                        cursor: 'pointer' // Pointer cursor on hover
                    }}
                    onClick={() => handleCardClick(app.scheme)}
                >
                    <img
                        src={app.image}
                        // alt={app.name}
                        style={{
                            width: '80px',
                            height: '80px',
                            objectFit: 'cover',
                            margin: '16px auto 8px auto',
                        }}
                    />
                    <CardContent>
                        <Typography variant="body2" noWrap>{app.name}</Typography>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
}

export default PayList;
