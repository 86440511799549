import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CFQrReader from './pages/cf-qr-reader';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CFQrReader />} />
        <Route path="/page/:str_cd/:order_uid" element={<CFQrReader />} />
      </Routes>
    </Router>
  );
}

export default App;
